import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = ['titular', 'dependente', 'dependenteContainer']

  checkForDependentes(e) {
    const $titularId = e === undefined ? $(this.titularTarget) : $(e.target)
    const titular = $titularId.select2('data')

    if(this.dependenteTarget.value === '') {
      this.dependenteContainerTarget.classList.add('d-none');
    } else {
      this.dependenteContainerTarget.classList.remove('d-none');
    }

    if(titular.dependentes?.length > 0) {
      $(this.dependenteTarget).select2({ data: titular.dependentes })
      this.dependenteContainerTarget.classList.remove('d-none');
    } else {
      this.dependenteContainerTarget.classList.add('d-none');
    }
  }

  connect() {
    console.log('START CONSULTA')
    // select2 not support native events. so stimulus actions not work
    $(document).on('change', '.titular-id', this.checkForDependentes.bind(this))
    // utilize trigger to dispatch non native events
    this.trigger(this.titularTarget, 'change')
    
  }

  disconnect() {
    $(document).off('change', '.titular_id', this.checkForDependentes.bind(this))
  }
}
