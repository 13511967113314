import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = []

  checkFonte(e) {
    let nome    = $('option:selected', e.target).val()
    let nivel   = $(e.target).parents('.col-md').next()
    let salario = $(e.target).parents('.col-md').next().next()

    if(nome == 'USP') {
      nivel.show()
      salario.hide()
    } else if(nome == 'FFM') {
      nivel.hide()
      salario.show()
    } else {
      nivel.hide()
      salario.hide()
    }
  }

  connect() {
    console.log('START FONTE PAGADORA')
    $(document).on('change', '.nome', this.checkFonte.bind(this))
    $('.nome').trigger('change')
  }

}
