import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  changeArrowDirection(event) {
     const $cliente = $(event.target).parent()
     $cliente.closest('tr').next().toggle()

     //se o icone da seta estiver pra baixa muda pra cima, e vice-versa
     var $icon = $cliente.children('span')

     if($icon.hasClass("fa-chevron-down")) {
       $icon.removeClass("fa-chevron-down")
       $icon.addClass("fa-chevron-up")
     } else {
       $icon.removeClass("fa-chevron-up")
       $icon.addClass("fa-chevron-down")
     }
   }

  showLancamentoDescricao(event) {
    const cliente = event.target
    const event_type = event.type

    //seleciona tr deste td, depois seleciona  todos tds do tr escolhido
    const row = $(cliente).closest('tr').children()

    if(event_type == 'mouseenter'){
      row.addClass('lancamento-row-over')
    } else {
      row.removeClass('lancamento-row-over')
    }
   }

  connect() {
    console.log('START LANCAMENTO INDEX')
    $('.lancamento-descricao').hide()
  }
}
