import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'my' ]

  connect() {
    console.log('START MONEY')
    const $field = $(this.myTarget)
    $field.mask('000000000000.00', { reverse : true})
  }

}
