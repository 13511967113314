import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'my' ]

  connect() {
    console.log('START SELECTBOXAJAX')
    const urlCollection = this.data.get('collection')

    $(this.myTarget).select2({
      placeholder: "Digite o nome",
      minimumInputLength: 3,
      ajax: { // instead of writing the function to execute the request we use Select2's convenient helper
        url:  `/${urlCollection}`,
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
              q: term, // search term
          };
        },
        results: function (data, page) { // parse the results into the format expected by Select2.
          // since we are using custom formatting functions we do not need to alter the remote JSON data
          return {
            results: $.map(data, function (item) {
              return {
                id: item.id,
                nome: item.nome,
                dependentes: item.dependentes
              }
            })
          };
        },
        cache: true
      },
      initSelection: function(element, callback) {
        const id = $(element).val()
        if (id !== '') {
          Rails.ajax({
            url: `/${urlCollection}/${id}`,
            type: 'GET', 
            dataType: 'json',
            success: data => callback(data),
            error: err => console.log(err),
          })
        }
      },

      formatResult: function(data, term){
        return data.nome
      },

      formatSelection: function(data){
        return data.nome
      },

      dropdownCssClass: "bigdrop", // apply css that makes the dropdown taller
      escapeMarkup: function (m) { return m } // we do not want to escape markup since we are displaying html in results

    })

  }

  disconnect() {
    $(this.myTarget).select2('destroy')
  }
}
