import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = []

  connect() {
    console.log('START EMAIL')
    $("a[href='#collapse6']").trigger('click')
  }

}
