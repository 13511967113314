require("@rails/ujs").start()
//require("@rails/activestorage").start()
require("turbolinks").start()
//require("channels")
//require("trix")
//require("@rails/actiontext")
import 'controllers'

import $ from 'jquery'
import 'jquery-mask-plugin'
import 'jquery-maskmoney/dist/jquery.maskMoney'
import 'cocoon-js'
import 'bootstrap'
import 'qtip2'
import 'flot'
import 'flot/jquery.flot.pie'
import 'flot/jquery.flot.categories'
import 'flot-axislabels/jquery.flot.axislabels'
import 'select2'
import 'select2/select2_locale_pt-BR'
import NProgress from 'nprogress'
import moment from 'moment'

import 'fullcalendar'
import 'fullcalendar/dist/locale/pt-br'

window.NProgress = NProgress
window.moment = moment

import Chartkick from 'chartkick'
window.Chartkick = Chartkick

import Chart from 'chart.js'
Chartkick.addAdapter(Chart)

import 'stylesheets/application'
