import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  edit(e) {
    const selectField = `<select name='dente' id='dente' class='form-control form-control-sm'>
      <option value ="18">18</option>
      <option value ="17">17</option>
      <option value="16">16</option>
      <option value="15 (55)">15 (55)</option>
      <option value="14 (54)">14 (54)</option>
      <option value="13 (53)">13 (53)</option>
      <option value="12 (52)">12 (52)</option>
      <option value="11 (51)">11 (51)</option>
      <option value="21 (61)">21 (61)</option>
      <option value="22 (62)">22 (62)</option>
      <option value="23 (63)">23 (63)</option>
      <option value="24 (64)">24 (64)</option>
      <option value="25 (65)">25 (65)</option>
      <option value="26">26</option>
      <option value="27">27</option>
      <option value="28">28</option>
      <option value="38">38</option>
      <option value="37">37</option>
      <option value="36">36</option>
      <option value="35 (75)">35 (75)</option>
      <option value="34 (74)">34 (74)</option>
      <option value="33 (73)">33 (73)</option>
      <option value="32 (72)">32 (72)</option>
      <option value="31 (71)">31 (71)</option>
      <option value="41 (81)">41 (81)</option>
      <option value="42 (82)">42 (82)</option>
      <option value="43 (83)">43 (83)</option>
      <option value="44 (84)">44 (84)</option>
      <option value="45 (85)">45 (85)</option>
      <option value="46">46</option>
      <option value="47">47</option>
      <option value="48">48</option></select >`

    let $tr = $(e.currentTarget).closest('tr')
    let operation = $tr.attr('data-operation')

    if (operation == 'edit') {
      $(e.currentTarget).closest('tr').children('td').each( function(index, el) {
        let $el = $(el)
          
        if ($el.hasClass('periodontal-dente')) {
          const cellValue = $el.text().trim()
          $el.html(selectField)
          $el.find('select').val(cellValue)
        }
        
        if ($el.hasClass('periodontal-descricao')) {
          const cellValue = $el.text().trim()
          $el.html("<textarea class='form-control form-control-sm'></textarea>")
          $el.find('textarea').val(cellValue)
        }
      })
      
      $(e.currentTarget).find('span').removeClass('fa-edit').addClass('fa-save')
      $(e.currentTarget).closest('tr').attr('data-operation', 'update') 

    } else {
      this.update(e)
    }
  }

  update(e) {   
    const $tr = $(e.currentTarget).closest('tr')
    const prontuarioOdontoId = $tr.attr('data-tecnica-prontuario-odonto-id')
    const id = $tr.attr('data-id')
    let params = ''

    $tr.children('td').each( function(index, el) {
      let $el = $(el)

      if ($el.hasClass('periodontal-dente')) {
        const $dente = $el.find('select')
        params += `prontuario_odonto_situacao_periodontal[dente]=${$dente.val().trim()}&`
      }
      
      if ($el.hasClass('periodontal-descricao')) {
        const $descricao = encodeURIComponent($el.find('textarea').val().trim())
        params += `prontuario_odonto_situacao_periodontal[descricao]=${$descricao}`
      }
    })

    Rails.ajax({
      type: 'PUT',
      url: `/tecnica/prontuario_odontos/${prontuarioOdontoId}/prontuario_odonto_situacao_periodontals/${id}.js`,
      data: params,
      success: () => console.log("success"),
      error: err => console.log(err),
    })
  }

  _new(e) {
    e.preventDefault()
    $('.create-periodontal-form').removeClass("d-none").addClass("d-flex");
  }

  create(e) {   
    const $tr = $(e.currentTarget).closest('tr')
    const prontuarioOdontoId = $tr.attr('data-tecnica-prontuario-odonto-id')
    let params = ''
    
    $tr.children('td').each(function(index, el) {
      let $el = $(el)

      if ($el.hasClass('periodontal-dente')) {
        const $dente = $el.find('select')
        params += `prontuario_odonto_situacao_periodontal[dente]=${$dente.val().trim()}&`
      }
      
      if ($el.hasClass('periodontal-descricao')) {
        const $descricao = encodeURIComponent($el.find('textarea').val().trim())
        params += `prontuario_odonto_situacao_periodontal[descricao]=${$descricao}`
      }
    })

    Rails.ajax({
      type: 'POST',
      url: `/tecnica/prontuario_odontos/${prontuarioOdontoId}/prontuario_odonto_situacao_periodontals.js`,
      data: params,
      success: () => console.log("success"),
      error: err => console.log(err),
    })
    
  }

  cancel(e) {
    e.preventDefault()
    $('.create-periodontal-form').removeClass("d-flex").addClass("d-none");
  }

  connect() {
    console.log('START PERIODONTAL')
  }
}
