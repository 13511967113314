import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "time" ]

  connect() {
    console.log('START TIMEPICKER')
    $(this.timeTarget).mask('00:00')

  }

}
