import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['saveButton', 'option']

  connect() {
    console.log('START ODONTOGRAM')
  }

  submit(e) {
    e.preventDefault()
    const idDenteLado = window.sessionStorage.getItem('dente-lado')
    const denteLado = document.getElementById(idDenteLado)
    const denteQ = document.getElementById(idDenteLado.replace(/\d$/, 'q'))
    const denteX = document.getElementById(idDenteLado.replace(/\d$/, 'x'))
    const selected = this.optionTargets.find(element => element.checked)
    
    if (selected) {
      switch(selected.value) {
        case '1':
          denteLado.style.fill = 'red'
          denteLado.style.stroke = 'red'
          break
        case '2':
          denteLado.style.fill = 'none'
          denteLado.style['stroke-width'] = '0.4px'
          denteLado.style.stroke = 'red'
          break
        case '3':
          denteLado.style.fill = 'blue'
          denteLado.style.stroke = 'blue'
          break
        case '4':
          denteLado.style.fill = 'none'
          denteLado.style['stroke-width'] = '0.4px'
          denteLado.style.stroke = 'blue'
          break
        case '5':
          denteLado.style.fill = 'white'
          denteLado.style['stroke-width'] = '0.2px'
          denteLado.style.stroke = 'black'
          denteQ.style.opacity = 0
          denteX.style.opacity = 0
          break
        case '6':
          denteQ.style.opacity = 1
          break
        case '7':
          denteX.style.opacity = 1
          break
      }
    }

    window.sessionStorage.clear()
    $('.modal-report').modal('hide')
  }

  select(e) {
    window.sessionStorage.setItem('dente-lado', e.currentTarget.id)
    $('h4.modal-title').text(e.currentTarget.id)
    $('.modal-body').html(`<div class="card" data-controller="odontogram">
  <div class="card-body">
    <div class="form-group">
      <label for="A__es:">Ações:</label>
      <br />
      <div class="form-check form-check-inline">
        <input type="radio" name="option" id="option_1" value="1" class="form-check-input" data-target="odontogram.option" />
        <label class="form-check-label">
          <span class="fas fa-square" style="font-size: 2em; color: red;">
</label>      </div>

      <div class="form-check form-check-inline">
        <input type="radio" name="option" id="option_2" value="2" class="form-check-input" data-target="odontogram.option" />
        <label class="form-check-label">
          <span class="far fa-square" style="font-size: 2em; color: red;">
</label>      </div>

      <div class="form-check form-check-inline">
        <input type="radio" name="option" id="option_3" value="3" class="form-check-input" data-target="odontogram.option" />
        <label class="form-check-label">
          <span class="fas fa-square" style="font-size: 2em; color: blue;">
</label>      </div>

      <div class="form-check form-check-inline">
        <input type="radio" name="option" id="option_4" value="4" class="form-check-input" data-target="odontogram.option" />
        <label class="form-check-label">
          <span class="far fa-square" style= "font-size: 2em; color: blue;">
</label>      </div>

      <div class="form-check form-check-inline">
        <input type="radio" name="option" id="option_5" value="5" class="form-check-input" data-target="odontogram.option" />
        <label class="form-check-label">
          <span class="far fa-square" style= "font-size: 2em;">
</label>      </div>

      <div class="form-check form-check-inline">
        <input type="radio" name="option" id="option_6" value="6" class="form-check-input" data-target="odontogram.option" />
        <label class="form-check-label">
          <span class="fas fa-question" style= "font-size: 2em; color: red;">
</label>      </div>

      <div class="form-check form-check-inline">
        <input type="radio" name="option" id="option_7" value="7" class="form-check-input" data-target="odontogram.option" />
        <label class="form-check-label">
          <span class="fas fa-times" style= "font-size: 2em; color: red;">
</label>      </div>
  </div>
  <div class="card-footer">
    <button name="button" type="submit" class="btn btn-primary" data-action="click-&gt;odontogram#submit">OK</button>
  </div>
</div>`)
    $('.modal-report').modal({
      show: true,
      keyboard: true
    })
  }

  save(e) {
    e.preventDefault()
    this.saveButtonTarget.disabled = true

    const prontuarioOdontoId = this.data.get('prontuarioOdontoId')
    const svg = document.getElementsByTagName('svg')[0]
    
    const formData = new FormData()
    const svgFile = "<?xml version='1.0' encoding='UTF-8' ?>\n" + svg.outerHTML 

    formData.append("prontuario_odonto_odontograma[svg]", svgFile)

    Rails.ajax({
      url: `/tecnica/prontuario_odontos/${prontuarioOdontoId}/prontuario_odonto_odontogramas.js`,
      type: 'POST',
      data: formData,
      success: () => console.log('success'),
      error: err => console.log(err),
      complete: () => this.saveButtonTarget.disabled = false
    })
  }

}