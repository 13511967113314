import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input', 'label' ];

  connect() {
    console.log('START COUNTER');
    this.calculate();
  }

  calculate(e) {
    const value = e !== undefined ? e.target.value.length : this.inputTarget.value.length;
    const remainingCharacters = parseInt(this.data.get('maxValue')) - value;
    this.labelTarget.innerHTML = remainingCharacters;

    if (remainingCharacters < 0) {
      this.labelTarget.classList.add('badge-danger');
      this.labelTarget.classList.remove('badge-success');
    } else {
      this.labelTarget.classList.add('badge-success');
      this.labelTarget.classList.remove('badge-danger');
    }
  }

}
