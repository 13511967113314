import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = []

  toggleSidebar() {
    $('#sidebar').toggleClass('active')
  }

  connect() {
    console.log('START SIDEBAR')
    $('#sidebarCollapse').on('click', this.toggleSidebar)
  }

}
