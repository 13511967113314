import { Controller } from "stimulus"

export class ApplicationController extends Controller {

  trigger(el, type){
    if ('createEvent' in document) {
      // modern browsers, IE9+
      let e = document.createEvent('HTMLEvents')
      e.initEvent(type, false, true) //can bubble, and is cancellable
      el.dispatchEvent(e)
    } else {
      // IE 8
      let e = document.createEventObject()
      e.eventType = type
      el.fireEvent('on'+e.eventType, e)
    }
  }

  delegate(eventName, parameters) {
    const handler = (...args) => {
      const data = {}
      parameters.forEach((name, i) => data[name] = args[i]);
      const delegatedEvent = new CustomEvent("jquery:" + eventName, {
        bubbles: true,
        cancelable: true,
        detail: data
      }
      )
      data.event.target.dispatchEvent(delegatedEvent)
    }
    $(document).on(eventName, handler)
  }

  undelegate(eventName) {
    $(document).off(eventName)
  }

}
