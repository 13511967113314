import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "multidt" ]

  connect() {
    console.log('START MULTI DATEPICKER')

    $(this.multidtTarget).flatpickr({
      "locale": "pt",
      mode: 'multiple',
      allowInput: true,
      dateFormat: "d/m/Y",
      static: true,
    })
  }

}
