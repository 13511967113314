import { Controller } from "stimulus"
import Rails from '@rails/ujs'

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START ODONTOGRAMA')
  }

  goto(e) {
    e.preventDefault()

    Rails.ajax({ 
      url: this.data.get('url'),
      success: () => console.log('success'),
      error: err => console.log(err),
    })
  }

  edit(e) {
    const $tr = $(e.currentTarget).closest('tr')
    const operation = $tr.attr('data-operation')

    if (operation === 'edit') {
      $(e.currentTarget).closest('tr').children('td').each( function(index, el) {
        const $el = $(el)
          
        if ($el.hasClass('dente-diario-descricao')) {
          const cellValue = $el.text().trim()
          $el.html("<textarea class='form-control form-control-sm'></textarea>")
          $el.find('textarea').val(cellValue)
        }
      })
      
      $(e.currentTarget).find('span').removeClass('fa-edit').addClass('fa-save')
      $(e.currentTarget).closest('tr').attr('data-operation', 'update') 

    } else {
      this.update(e)
    }
    
  }

  update(e) {   
    const $tr = $(e.currentTarget).closest('tr')
    const prontuarioOdontoId = $tr.attr('data-tecnica-prontuario-odonto-id')
    const prontuarioOdontoDenteId = $tr.attr('data-tecnica-prontuario-odonto-dente-id')
    const id = $tr.attr('data-id')
    let params = ''

    $tr.children('td').each( function(index, el) {
      let $el = $(el)

      if ($el.hasClass('dente-diario-descricao')) {
        const $descricao = encodeURIComponent($el.find('textarea').val().trim())
        params = `prontuario_odonto_dente_diario[descricao]=${$descricao}`
      }
    })

    Rails.ajax({
      type: 'PUT',
      url: `/tecnica/prontuario_odontos/${prontuarioOdontoId}/prontuario_odonto_dentes/${prontuarioOdontoDenteId}/prontuario_odonto_dente_diarios/${id}.js`,
      data: params,
      success: () => console.log('success'),
      error: err => console.log(err),
    })
  }

  _new(e) {
    e.preventDefault()
    const denteId = e.currentTarget.dataset.denteId
    $(`#create-dente-diario-form-${denteId}`).removeClass("d-none").addClass("d-flex");
  }

  create(e) {   
    const $tr = $(e.currentTarget).closest('tr')
    const prontuarioOdontoId = $tr.attr('data-tecnica-prontuario-odonto-id')
    const prontuarioOdontoDenteId = $tr.attr('data-tecnica-prontuario-odonto-dente-id')
    let params = ''
    
    $tr.children('td').each( function(index, el) {
      let $el = $(el)

      if ($el.hasClass('dente-diario-descricao')) {
        const $descricao = encodeURIComponent($el.find('textarea').val().trim())
        params = `prontuario_odonto_dente_diario[descricao]=${$descricao}`
      }
    })

    Rails.ajax({
      type: 'POST',
      url: `/tecnica/prontuario_odontos/${prontuarioOdontoId}/prontuario_odonto_dentes/${prontuarioOdontoDenteId}/prontuario_odonto_dente_diarios.js`,
      data: params,
      success: () => console.log('success'),
      error: err => console.log(err),
    })
  }

  cancel(e) {
    e.preventDefault()
    const denteId = e.currentTarget.dataset.denteId
    $(`#create-dente-diario-form-${denteId}`).removeClass("d-flex").addClass("d-none");
  }
}
