import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "q" ]

  connect() {
    console.log('START SEARCH')
    const source = this.data.get("source")

    $(this.qTarget).typeahead({
      minLength: 3,
      order: "asc",
      hint: true,
      accent: true,
      // backdrop: {
      //   "background-color": "#3879d9",
      //   "opacity": "0.1",
      //   "filter": "alpha(opacity=10)"
      // },
      display: ["name"],
      template: function (query, item) {
        let template

        if (item.inactive == 'S') {
          template = `<span class="help-inline">${ item.name }</span>`
        } else {
          template = `<span>${ item.name }</span>`
        }

        return template
      },
      source: {
        url: source
      }
    })

  }
}
