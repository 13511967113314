import { Controller } from "stimulus"
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min'

export default class extends Controller {
  static targets = ["month"]

  connect() {
    console.log('START MONTHPICKER')

    $(this.monthTarget).datepicker({
      language: "pt-BR",
      startView: "months",
      minViewMode: "months",
      format: "mm/yyyy"
    })
  }

}