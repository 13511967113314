import { ApplicationController } from './application_controller'
import Rails from "@rails/ujs"

export default class extends ApplicationController {
  static targets = ['associadoId', 'associadoDetail']

  showAssociadoDetails(e) {
    const associadoId = this.associadoIdTarget.value

    if (associadoId) {
      Rails.ajax({
        url: `/contas_receber/associados/${associadoId}.json`,
        type: 'GET',
        dataType: 'json',
        success: associado => {
          $(this.associadoDetailTarget).html(`<b>Endereço Residencial: </b>${associado.associado_detalhe?.endereco}<br />
                            <b>Bairro: </b>${associado.associado_detalhe?.bairro}<br />
                            <b>CEP: </b>${associado.associado_detalhe?.cep}<br />
                            <b>Cidade: </b>${associado.associado_detalhe?.cidade}<br />
                            <b>Estado: </b>${associado.associado_detalhe?.uf}<br />
                            <b>Fone: </b>${associado.associado_detalhe?.fone_resid} / ${associado.associado_detalhe?.fone_com}<br />
                            <b>Estado Civil: </b>${associado.associado_detalhe?.estado_civil}<br />
                            <b>Email: </b>${associado.email}<br />`
          )
        },
        error: err => {
          $(this.associadoDetailTarget).html(`<b>Erro ao acessar o serviço!</b>`)
        }
      })

      $(this.associadoDetailTarget).fadeIn('slow')

    } else {
      $(this.associadoDetailTarget).fadeOut()
      $(this.associadoDetailTarget).html("")
    }
  }

  connect() {
    console.log('START SHOW ASSOCIADO')
    this.showAssociadoDetails()
    this.delegate('change', ['event'])
  }

  disconnect() {
    this.undelegate('change')
  }
}