import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  verifRb(event) {
    const rbRemarc = "input[name=consultorio_resultado_consulta\\[remarcou\\]]"
    const $rb = $(event.target)
    const group = $('.justificativa')

    if ($rb.val() == 'false') {
      group.show()
      $(rbRemarc).attr('disabled',false)
    } else {
      group.hide()
      $(rbRemarc).val(['false'])
      $(rbRemarc).attr('disabled',true)
    }
  }

  connect() {
    console.log('START RESULTADO CONSULTA')
    const rbCompareceu = "input[name=consultorio_resultado_consulta\\[compareceu\\]]:checked"

    $(document).on('click', rbCompareceu, this.verifRb)
    $(rbCompareceu).trigger('click')
  }
}
