import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  edit(e) {
    let $tr = $(e.currentTarget).closest('tr')
    let operation = $tr.attr('data-operation')

    if (operation == 'edit') {
      $(e.currentTarget).closest('tr').children('td').each( function(index, el) {
        let $el = $(el)
          
        if ($el.hasClass('diario-descricao')) {
          const cellValue = $el.text().trim()
          $el.html("<textarea class='form-control form-control-sm'></textarea>")
          $el.find('textarea').val(cellValue)
        }
      })
      
      $(e.currentTarget).find('span').removeClass('fa-edit').addClass('fa-save')
      $(e.currentTarget).closest('tr').attr('data-operation', 'update') 

    } else {
      this.update(e)
    }
    
  }

  update(e) {   
    const $tr = $(e.currentTarget).closest('tr')
    const prontuarioOdontoId = $tr.attr('data-tecnica-prontuario-odonto-id')
    const id = $tr.attr('data-id')
    let params = ''

    $tr.children('td').each( function(index, el) {
      let $el = $(el)

      if ($el.hasClass('diario-descricao')) {
        const $descricao = encodeURIComponent($el.find('textarea').val().trim())
        params = `prontuario_odonto_diario[descricao]=${$descricao}`
      }
    })

    Rails.ajax({
      type: 'PUT',
      url: `/tecnica/prontuario_odontos/${prontuarioOdontoId}/prontuario_odonto_diarios/${id}.js`,
      data: params,
      success: () => console.log("success"),
      error: err => console.log(err),
    })
  }

  _new(e) {
    e.preventDefault()
    $('.create-diario-form').removeClass("d-none").addClass("d-flex");
  }

  create(e) {
    const $tr = $(e.currentTarget).closest('tr')
    const prontuarioOdontoId = $tr.attr('data-tecnica-prontuario-odonto-id')
    let params = ''

    $tr.children('td').each( function(index, el) {
      let $el = $(el)

      if ($el.hasClass('diario-descricao')) {
        const $descricao = encodeURIComponent($el.find('textarea').val().trim())
        params = `prontuario_odonto_diario[descricao]=${$descricao}`
      }
    })

    Rails.ajax({
      type: 'POST',
      url: `/tecnica/prontuario_odontos/${prontuarioOdontoId}/prontuario_odonto_diarios.js`,
      data: params,
      success: () => console.log("success"),
      error: err => console.log(err),
    })
    
  }

  cancel(e) {
    e.preventDefault()
    $('.create-diario-form').removeClass("d-flex").addClass("d-none");
  }

  connect() {
    console.log('START DIARIO')
  }
}
