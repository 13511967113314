import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START UPLOADER')
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const url = this.data.get('url')
    const method = this.data.get('method')

    const formData = new FormData(e.currentTarget)

    Rails.ajax({
      url,
      type: method,
      data: formData,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: () => console.log('success'),
      error: err => console.log(err),
    })

  }
}
