import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = []

  edit(e) {
    const $tr         = $(e.target).closest('tr')
    const operation   = $tr.attr('data-operation')
    
    if (operation == 'edit') {
      $(e.target).closest('tr').children('td:nth-child(-n+3)').each( function(index, el) {
        const $el = $(el)
  
        if ($el.hasClass('produto-nome')) {
          let cellValue = $el.attr('data-id')
          $el.html(`<div data-controller='selectboxajax' data-selectboxajax-collection='estoque/produtos'><input class='form-control form-control-sm' type='text' data-target='selectboxajax.my'></input></div>`)
          $el.find('input').val(cellValue)
        }
  
        if ($el.hasClass('produto-valor-custo')) {
          let cellValue = $el.text().trim()
          cellValue = cellValue.replace(/R\$/,'').replace(/,/,'.')
          $el.html("<div data-controller='money'><input class='form-control form-control-sm' type='text' data-target='money.my'></input></div>")
          $el.find('input').val(cellValue)
        }
        
        if ($el.hasClass('produto-valor-venda')) {
          let cellValue = $el.text().trim()
          cellValue = cellValue.replace(/R\$/,'').replace(/,/,'.')
          $el.html("<div data-controller='money'><input class='form-control form-control-sm' type='text' data-target='money.my'></input></div>")
          $el.find('input').val(cellValue)
        }
      })
      
      $(e.target).text('Salvar')
      $(e.target).closest('tr').attr('data-operation', 'update') 

    } else {
      this.update(e)
    }
    
  }

  update(e) {   
    const $tr =         $(e.target).closest('tr')
    const listaId =     $tr.attr('data-lista-id')
    const id =          $tr.attr('data-id')
    let params =      ''
    let cellValue =   ''

    $tr.children('td:nth-child(-n+3)').each( function(index, el) {
      const $el = $(el)

      if ($el.hasClass('produto-nome')) {
        cellValue = $el.find('input').select2('data').id
      }

      if ($el.hasClass('produto-valor-custo')) {
        cellValue = $el.find('input').val().trim()
      }

      if ($el.hasClass('produto-valor-venda')) {
        cellValue = $el.find('input').val().trim()
      }

      let i = $el.closest('tr').children('td').index($el) + 1
      let key = $('#produtos-table').find(`th:nth-child(${i})`).attr('data-attribute')
      params += `estoque_preco_produto[${key}]=${cellValue}&`
    })

    $.ajax({
      type: 'PUT',
      url: `/estoque/lista_precos/${listaId}/preco_produtos/${id}.js`,
      data: params
    })
    .done(function() {
      console.log( "success" )
    })
    .fail(function (xhr, status, error) {
      console.log("error", xhr.responseText)
    })
    .always(function() {
      console.log( "complete" )
    })

  }

  _new(e) {
    e.preventDefault()
    $('.create-preco-produto-form').show()
  }

  create(e) {   
    const $tr =         $(e.target).closest('tr')
    const listaId =     $tr.attr('data-lista-id')
    let params =      ''
    let cellValue =   ''
    
    $tr.children('td:nth-child(-n+3)').each( function(index, el) {
      const $el = $(el)

      if ($el.hasClass('produto-nome')) {
        let $produto = $el.find('input').select2('data')
        cellValue =  ($produto == null) ? '' : $produto.id
      }

      if ($el.hasClass('produto-valor-custo')) {
        cellValue = $el.find('input').val().trim()
      }

      if ($el.hasClass('produto-valor-venda')) {
        cellValue = $el.find('input').val().trim()
      }

      let key = $('#produtos-table').find(`th:nth-child(${index+1})`).attr('data-attribute')
      params += `estoque_preco_produto[${key}]=${cellValue}&`
    })

    Rails.ajax({
      type: 'POST',
      url: `/estoque/lista_precos/${listaId}/preco_produtos.js`,
      data: params,
      success: () => console.log("success"),
      error: (xhr, status, error) => console.log("error", xhr.responseText),
      complete: () => console.log("complete"),
    })
  }

  cancel(e) {
    e.preventDefault()
    $('.create-preco-produto-form').hide()
  }

  connect() {
    console.log('START LISTA PRECO PRODUTO')
  }

}