import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START DEPENDENTE')

    $(document)
      .on('cocoon:before-remove', (e, item, originalEvent) => {
        $(item).find('[data-name=situacao]').val('Excluído')
      })
  }

  disconnect() {
    $(document)
      .off('cocoon:before-remove', () => {
        console.log('off cocoon:before-remove')
      })
  }

}
