import flatPickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["time"];

  connect() {
    console.log('START DATETIMEPICKER');

    this.fp = flatpickr(this.timeTarget, {
      "locale": "pt",
      static: true,
      enableTime: true,
      noCalendar: true,
      allowInput: true,
      time_24hr: true,
      dateFormat: "H:i",
    });
  }

  disconnect() {
    this.fp.destroy();
  }

}