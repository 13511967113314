import { ApplicationController } from "./application_controller"
import Rails from '@rails/ujs'

export default class extends ApplicationController {
  static targets = [ 'associado'
                   , 'total'
                   , 'totalProduto'
                   , 'qtdeProduto'
                   , 'valorProduto'
                   , 'nomeProduto'
                   , 'parcelado'
                   , 'numParcelas'
                   ]

  resetValues() {
    let trigger = this.trigger
    window.sessionStorage.clear()

    if (this.hasNomeProdutoTarget)
      this.nomeProdutoTargets.forEach(el => trigger(el, 'change'))
  }

  zeroValues(event, item) {
    let $qtde    = $(item).find(".qtde")
    let $produto = $(item).find(".produto")
    let $valor   = $(item).find(".valor")

    $qtde.prop('required',false)
    $valor.prop('required',false)

    if($produto)
      $produto.prop('required',false)

    $valor.val(0)
    this.calculate()
  }

  getProductValues(event) {
    const produto_id = event.target.value
    const valor      = event.target.parentNode.nextSibling.nextSibling
    const associado  = this.associadoTarget.value
    const trigger    = this.trigger

    // verifica se o dado já não está armazenado na sessionStorage
    const session_produto_id = window.sessionStorage.getItem(`product${produto_id}`)

    if(session_produto_id === null) {
      Rails.ajax({
        url: `/contas_receber/produtos/${produto_id}/value.json?associado=${associado}`, 
        type: 'GET',
        dataType: 'json',
        success: produto => {
          window.sessionStorage.setItem(`product${produto_id}`, produto.valor)
          valor.childNodes[3].value = produto.valor
          trigger(valor.childNodes[3], 'input')
        },
        error: err => {
          console.log(err)
        }
      })
    } else {
      valor.childNodes[3].value = session_produto_id
      trigger(valor.childNodes[3], 'input')
    }
  }

  arred(d,casas) {
     const aux = Math.pow(10,casas)
     return Math.floor(d * aux) / aux
   }

  calculate() {
    let adder_produto = 0

    //calculate products
    if (this.hasValorProdutoTarget) {
      this.valorProdutoTargets.forEach((el, index) => {
        let valor = el
        let qtde = this.qtdeProdutoTargets[index]

        if (!qtde.value) return
        if (!el.value) return

        adder_produto += (el.value * qtde.value)
      })
    }
    this.totalProdutoTarget.textContent = this.arred(adder_produto, 2)
  }

  showNumParcelas() {
    const $parcelado = $(this.parceladoTarget)

    if($parcelado.prop('checked')) {
      $('.num-parcelas').show()
    } else {
      $('.num-parcelas').hide()
      $('#contas_receber_lancamento_num_parcelas').val(1)
      $('.parcelas-valor span').text('')
    }
  }

  calculateParcelado(){
    const numParcelas = $(this.numParcelasTarget).val()
    const total = $('.total span').text()
    const parcelaValor = this.arred((total / numParcelas), 2)

    $('.parcelas-valor span').text(numParcelas + " X " + parcelaValor)
  }

  connect() {
    console.log('START LANCAMENTO')
    $("a[href='#collapse3']").trigger('click')
    window.sessionStorage.clear()

    $(document).on('cocoon:before-remove', this.zeroValues.bind(this))
    $(document).on('cocoon:before-insert', function(e, item){
      $(item).find('.qtde').val(1)
    })
    $(document).on('change', '#contas_receber_lancamento_associado_id', this.resetValues.bind(this))

    $('.num-parcelas').hide()
    this.calculate()
  }

}
