import { Controller } from "stimulus"
import Rails from "@rails/ujs" 

export default class extends Controller {
  static targets = []

  getAniversariante() {
    Rails.ajax({
      url: `/contas_receber/associados/aniversariantes.json`,
      type: 'GET',
      dataType: 'json',
      success: () => {
        const dashboardVisits = parseInt($('#dashboard-visits').data('visits'))
        if (dashboardVisits <= 1)
          document.getElementById('link-birthday').click()
      },
      error: err => {
        $('.ajax-alert').html(`<b>Erro ao acessar o serviço. ${err}</b>`).css("background-color", "red")
      }
    })
  }

  loadAgenda(profissional_id) {
    const $container = $('#calendar')
    let ano
    let has_calendar = ($container.children().length > 0) ? true : false
    let that = this

    if(has_calendar){
      ano = $container.fullCalendar('getDate').endOf('week').format('YYYY')
    } else {
      ano = (new Date()).getFullYear()
    }

    Rails.ajax({
      url: `/consultorio/profissionals/${profissional_id}/default_agenda.json?ano=${ano}`,
      type: 'GET',
      dataType: 'json',
      success: agenda => {
        if (agenda == null) {
          $container.fullCalendar('removeEventSources')
          $('.ajax-alert').html("<b>Agenda do profissional não localizada.</b>").css("background-color", "red")
          return
        }

        if (has_calendar) {
          $container.fullCalendar('removeEventSources')
          $container.fullCalendar('addEventSource', `/consultorio/agendas/${agenda.id}/agenda_dias`)
        } else {
          that.calendar(agenda)
        }
      },
      error: err => {
        $('.ajax-alert').html(`<b>Erro ao acessar o serviço. ${err.message}</b>`).css("background-color", "red")
      }
    })
  }

  getSelectedProfissional(event) {
    const profissional_id = $('option:selected', event.target).val()
    $('.ajax-alert').html("").css("background-color","white")

    if(profissional_id) {
      this.loadAgenda(profissional_id)
    }
  }

  calendar(agenda) {
    const $container = $('#calendar')

    $container.fullCalendar({
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,listWeek'
      },

      defaultView: 'agendaWeek',
      weekends: false,
      aspectRatio: 2,
      lang: 'pt-br',

      eventSources: [{
        url: `/consultorio/agendas/${agenda.id}/agenda_dias`,
      }],

      views: {
        agendaWeek: { // name of view
          allDaySlot: false,
          slotLabelFormat: ' ',
          displayEventEnd: false,
          //slotDuration: `00:${agenda.duracao_consulta}:00`,
          slotDuration: '00:40:00',
          minTime: '07:00',
          maxTime: '23:59'
        }
      },

      loading: bool => {
        bool ? $("#loading-indicator").show() : $("#loading-indicator").hide()
      },

      viewRender: (view, element) => $('#profissional_id').trigger('change'),

      eventRender: (event, element) => {
        const dt_init = moment(event.start).format('DD/MM/YYYY - HH:mm:SS')
        const dt_final = moment(event.end).format('DD/MM/YYYY - HH:mm:SS')
        let consulta_data = ''
        let re = /NÃO\./
        let totalDesmarc = 0
        let edit = ''
        let pos_consulta = ''

        if(event.edit && event.end > moment())
          edit = `<a href="/consultorio/agendas/${event.agenda_id}/agenda_dias/${event.id}/edit/" class="btn btn-sm btn-block btn-primary" data-remote="true" >MARCAR/EDITAR CONSULTA(S)</a>`

        if(event.consultas.length > 0) {
          event.consultas.forEach((consulta, index) => {

            if(re.test(consulta.descricao))
              totalDesmarc++

            if(totalDesmarc == event.consultas.length)
              element.find('.fc-content').addClass("fc-consulta-desmarc")

            if(!(consulta.realizada) && event.edit_pos_consulta)
              pos_consulta = `<a href="/consultorio/resultado_consultas/new?consulta_id=${consulta.id}" class="btn btn-sm btn-block btn-success" data-remote="true" >REGISTRAR PÓS-CONSULTA</a>`

            consulta_data +=  '<div class="qtip-event-content card-consulta-calendar">'
                           + `<p class="qtip-event-content-associado">${consulta.associado}</p>`
                           + `<p>${consulta.profissional}</p>`
                           + `<p><em>${consulta.descricao}</em></p>`
                           + pos_consulta
                           + '</div>'
                           + '<br /><br />'
          })
        }

        const msg = `<p class=\'qtip-event-date\'{dt_init}</p>`
                + consulta_data
                + edit

        $(element).qtip({
          content: {
            text: msg,
            title: {
              //text: event_title,
              button: true
            }
          },
          show: 'mousedown',
          events: {
            show: (event, api) => {
              // Only show the tooltip if it was a right-click
              if(event.originalEvent.button !== 2) {
                event.preventDefault()
              }
            }
          },
          hide: {
            event: 'click'
          },
          style: {
            classes: 'qtip-tipped',
            width: 500,
            //height: 200
          },
          position: {
            my: 'top right',
            at: 'center bottom',
            target: $(element)
          }
        })
      },

      dayClick: (date, jsEvent, view) => {
        const profissional_id = $('#profissional_id').val()
        const ano = $container.fullCalendar('getDate').endOf('week').format('YYYY')

        Rails.ajax({
          url: `/consultorio/profissionals/${profissional_id}/default_agenda.json?ano=${ano}`,
          type: 'GET',
          dataType: 'json',
          success: agenda => {
            Rails.ajax({
              url: `/consultorio/agendas/${agenda.id}/agenda_dias/new?date=${date.format()}`,
              type: 'GET',
              dataType: 'script',
            })
          },
          error: err => {
            $('.ajax-alert').html(`<b>Erro ao acessar o serviço. ${err.message}</b>`).css("background-color", "red")
          }
        })
      },

      eventClick: (calEvent, jsEvent, view) => {
        const id = calEvent.id

        if (calEvent.destroy) { // can destroy event?
          if (confirm('Tem certeza que deseja apagar esse horário?')){
            Rails.ajax({
              url: `/consultorio/agendas/${calEvent.agenda_id}/agenda_dias/${id}/`,
              type: "DELETE",
              dataType: 'json',
            });
            $('#profissional_id').trigger('change')
          }
        }

        return false //because calEvent has url attribute
      }
    })
  }

  connect() {
    console.log('START CALENDAR')

    $(document).on('change keypress', '#profissional_id', this.getSelectedProfissional.bind(this))
    $(document).on('contextmenu', e => e.preventDefault() )

  }

}
