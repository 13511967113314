import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = [ 'buttonTrue', 'buttonFalse' ]

  verifRbChooseFalse(e) {
    let options = ['false']
    let $rb = $(e.target)
    let $group = $rb.parents('.form-group').next()
    let rb_value = $rb.val()

    if($.inArray(rb_value, options) != -1)
      $group.show()
    else
      $group.hide()
  }

  verifRbChooseTrue(e) {
    let options = ['true']
    let $rb = $(e.target)
    let $group = $rb.parents('.form-group').next()
    let rb_value = $rb.val()

    if ($.inArray(rb_value, options) != -1)
      $group.show()
    else
      $group.hide()
  }

  verifRbToogle(e) {
    var $rb = $(e.target)
    var $group1 = $rb.parents('.form-group').next()
    var $group2 = $rb.parents('.form-group').next().next()

    if($rb.val() in {'true':''}) {
      $group1.show()
      $group2.hide()
    } else {
      $group1.hide()
      $group2.show()
    }
  }

  connect() {
    console.log('START RADIO BUTTON')

    const that = this
    this.buttonTrueTargets.forEach((el, i) => {
      if (el.checked) {
        el.addEventListener('click', this.verifRbChooseTrue)
        that.trigger(el, 'click')
        el.removeEventListener('click', this.verifRbChooseTrue)
      }
    })

    this.buttonFalseTargets.forEach((el, i) => {
      if (el.checked) {
        el.addEventListener('click', this.verifRbChooseFalse)
        that.trigger(el, 'click')
        el.removeEventListener('click', this.verifRbChooseFalse)
      }
    })

  }

}