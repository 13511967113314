import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'phone', 'celphone' ]

  connect() {
    console.log('START PHONE')

    $(this.phoneTarget).mask("(99) 9999-9999");
    $(this.celphoneTarget).mask("(99) 99999-9999");
  }
}
