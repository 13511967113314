import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START POPOVER')
    
    $('a[rel=popover]').popover({
      html: true,
      trigger: 'hover'
    })
  }

}
