import { ApplicationController } from "./application_controller"

export default class extends ApplicationController {
  static targets = []

  calculateTotal(){
    let total = 0.00
    const that = this
    $('.check-box-lancamento:checked').each(function() {
      total += that.arred($(this).data('valor'), 2)
    })

    $('.pgto-valor').val(total)
    that.trigger($('.pgto-valor').get(0), 'change' )
  }

  calculateTotalFromSelectAll(event) {
    const $selectAllCheckBox = $(event.target)

    if($selectAllCheckBox.prop('checked')) {
      $('.check-box-lancamento').each(function() {
        $(this).prop('checked', true)
      })
    } else {
      $('.check-box-lancamento').each(function() {
        $(this).prop('checked', false)
      })
    }
    this.calculateTotal()
  }

  arred(d,casas) {
    const aux = Math.pow(10,casas);
    return Math.floor(d * aux) / aux;
  }

  showLancamentoDescricao(event) {
    const cliente = event.target
    const eventType = event.type
    //seleciona tr deste td, depois seleciona  todos tds do tr escolhido
    const row = $(cliente).closest('tr').children()

    if(eventType == 'mouseenter'){
      row.addClass('lancamento-row-over')
    } else {
      row.removeClass('lancamento-row-over')
    }
  }

  changeArrowDirection(event) {
    const $pgtoData = $(event.target).parent()
    $pgtoData.closest('tr').next().toggle()
    //se o icone da seta estiver pra baixa muda pra cima, e vice-versa
    const $icon = $pgtoData.children('span')

    if ($icon.hasClass("fa-chevron-down")) {
      $icon.removeClass("fa-chevron-down")
      $icon.addClass("fa-chevron-up")
    } else {
      $icon.removeClass("fa-chevron-up")
      $icon.addClass("fa-chevron-down")
    }
  }

  displayValorTotal(valor) {
    $('.total-pgto span').text(valor)
  }

  selectFormaPgto(event) {
    const forma = $(event.target).val()
    let valor = this.arred($('.pgto-valor').val(), 2)

    if (forma == 'CRÉDITO') {
      $('.parcelas').show()
      this.trigger($('.num-parcelas').get(0), 'change' )
    } else if (forma == 'DÉBITO') {
      $('.parcelas').hide()
      valor = this.arred((valor + valor * (2.3/100)), 2)
      this.displayValorTotal(valor)
    } else if (forma == 'DINHEIRO') {
      $('.parcelas').hide()
      this.displayValorTotal(valor)
    }
  }

  getValorTotal(event) {
    const valor = $("option:selected", event.target ).text()
    const arr = valor.split('=>')
    
    if (arr)
      this.displayValorTotal(arr[1].trim())
  }

  connect() {
    console.log('START PGTO')

    $('.lancamento-descricao').hide()
    $('.parcelas').hide()
  }
}
