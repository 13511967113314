import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START PROGRESS')

    const template =
    `<div class="splash card-splash">
        <div role="spinner">
            <div class="spinner-icon"></div>
        </div>
        <p class="lead" style="text-align:center">Aguarde...</p>
        <div class="progress">
            <div class="mybar" role="bar">
        </div>
        </div>
    </div>`

    NProgress.configure({ template })

    $(document)
      .on('turbolinks:click', () => {
        NProgress.start()
        console.log('turbo:click')
      })
      .on('turbolinks:render', () => {
        NProgress.done()
        NProgress.remove()
        console.log('turbo:render')
      })
      .on('ajax:send', () => {
        NProgress.start()
        console.log('ajax:send')
      })
      .on('ajax:complete', () => {
        NProgress.done()
        NProgress.remove()
        console.log('ajax:complete')
      })
      .on('ajax:success', () => {
        NProgress.done()
        NProgress.remove()
        console.log('ajax:success')
      })
      .on('ajax:error', () => {
        NProgress.done()
        NProgress.remove()
        console.log('ajax:error')
      })
      .on('ajax:aborted:required', () => {
        NProgress.done()
        NProgress.remove()
        console.log('ajax:aborted:required')
      })
      .on('ajax:aborted:file', () => {
        NProgress.done()
        NProgress.remove()
        console.log('ajax:aborted:file')
      })
  }
  
  disconnect() {
    $(document)
      .off('turbolinks:click', () => {
        NProgress.start()
        console.log('off turbo:click')
      })
      .off('turbolinks:render', () => {
        NProgress.done()
        NProgress.remove()
        console.log('off turbo:render')
      })
      .off('ajax:send', () => {
        NProgress.start()
        console.log('off ajax:send')
      })
      .off('ajax:complete', () => {
        NProgress.done()
        NProgress.remove()
        console.log('off ajax:complete')
      })
      .off('ajax:success', () => {
        NProgress.done()
        NProgress.remove()
        console.log('off ajax:success')
      })
      .off('ajax:error', () => {
        NProgress.done()
        NProgress.remove()
        console.log('off ajax:error')
      })
      .off('ajax:aborted:required', () => {
        NProgress.done()
        NProgress.remove()
        console.log('off ajax:aborted:required')
      })
      .off('ajax:aborted:file', () => {
        NProgress.done()
        NProgress.remove()
        console.log('off ajax:aborted:file')
      })
  }

}